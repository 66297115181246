import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/@marinda/gatsby-theme-recipes/src/components/MarkdownPagesLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Image = makeShortcode("Image");
const StyledParagraph = makeShortcode("StyledParagraph");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Image alt="About" src="/images/about.jpg" position="right" mdxType="Image" />
    <h1>{`About`}</h1>
    <StyledParagraph mdxType="StyledParagraph">A simple site for displaying tested and approved recipes.</StyledParagraph>
    <p>{`You'll find a mix of French / English recipes.`}<br parentName="p"></br>{`
`}{`However, all recipes are Vegan / Plant-based ✌️`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      